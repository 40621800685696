import { getClient } from '@utils/sanity/sanity.server';
import { GetStaticProps } from 'next';
import { FunctionComponent } from 'react';

import globalQuery from '../utils/queries/globalQuery';
import routeQuery from '../utils/queries/routeQuery';
import Page from './[slug]';
type Props = {
  data;
  preview;
};

const Index: FunctionComponent<Props> = (props) => {
  return Page(props);
};

// export async function getStaticProps({ res, preview = false }) {
export const getStaticProps: GetStaticProps = async ({ preview = false }) => {
  const slug = 'hjem';
  const queryParams = { slug };

  if (preview) {
    return { props: { preview, data: { queryParams } } };
  }

  let data = await getClient(preview).fetch(routeQuery, { slug });
  let global = await getClient(preview).fetch(globalQuery);

  if (Array.isArray(global)) {
    global = global.slice(-1)[0];
  }

  if (Array.isArray(data)) {
    data = data.slice(-1)[0];
  }

  return {
    props: { data, global, preview },
    revalidate: 60 * 20, // 20 minutes
  };
};

export default Index;
