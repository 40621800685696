import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';

type Props = {
  text?: string;
};

const EmptyPage: FunctionComponent<Props> = ({ text }) => {
  return (
    <div className="sanity-error-page sanity-error-page--404">
      <div>{text}</div>
    </div>
  );
};

EmptyPage.propTypes = {
  text: PropTypes.string.isRequired,
};

EmptyPage.defaultProps = {
  text: 'Empty page',
};

export default EmptyPage;
